import React, { Component } from 'react';
import { array } from 'prop-types';
import './css/index.css';

export default class extends Component {

    static name = 'suggested-search';

    // TODO: create shape for proptypes data array obj
    static propTypes = {
        data: array.isRequired
    };

    createLinks = ( data ) => {

        return data.map( ( link, index ) => {

            return (
                <li key={ `suggested-link-${ index }` }><a href={ link.url }>{ link.title }</a></li>
            );

        } );

    };

    render() {

        const links = this.createLinks( this.props.data );

        return (
            <div className='suggested-search'>
                <h4>Featured Topics</h4>
                <ul>{ links }</ul>
            </div>
        );

    }

}