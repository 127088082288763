import './css/index.css';
import React, { Component } from 'react';
import { array, string, bool, object } from 'prop-types';
import { createPortal } from 'react-dom';
import globalVPS from 'js/global-vps';
import focusTrap from 'js/focus-trap';
import SearchBox from 'components/search-box/component';
import SuggestedSearch from 'components/suggested-search/component';

export default class extends Component {

    static propTypes = {
        suggestedSearch: array,
        searchPagePath: string,
        endpoint: string,
        isOpen: bool,
        refocusElement: object.isRequired,
        wrapper: object.isRequired
    };

    constructor( props ) {

        super( props );

        this.state = {
            isSVP: globalVPS.current( 'small' ),
            isOpen: props.isOpen
        };

        this.unsub = null;
        this.pageBody = document.querySelector( 'body' );
        this.focusTrap = focusTrap( props.wrapper );

    }

    componentDidMount() {

        this.setup();

    }

    componentDidUpdate( prevProps, prevState, snapshot ) {

        const didPropsChange = this.props !== prevProps;
        const didStateChange = this.state !== prevState;

        if ( didStateChange && !this.state.isOpen ) this.cleanup();

        if ( didPropsChange && !didStateChange ) {

            this.props.isOpen && this.setup();

            this.setState( {
                isOpen: this.props.isOpen
            } );

        }

    }

    setup = () => {

        this.unsub = globalVPS( 'small', ( state ) => {

            if ( this.state.isSVP === state.current ) return;

            this.setState( {
                isSVP: state.current
            } );

        } );

        this.focusTrap.on();
        this.pageBody.setAttribute( 'aria-hidden', true );

    };

    cleanup = () => {

        this.unsub();
        this.focusTrap.off();
        this.pageBody.setAttribute( 'aria-hidden', false );
        this.props.wrapper.classList.remove( 'expanded' );
        this.props.refocusElement.focus();

    };

    closeOverlay = () => {

        this.setState( { isOpen: false } );

    };

    renderOverlay = () => {

        const portal = createPortal(
            <div
                className='overlay'
                role={ this.state.isOpen ? 'dialog' : '' }
                aria-hidden={ this.state.isOpen ? false : true }
            >
                <div className='overlay-header'>
                    { !this.state.isSVP && <a href='/' className='overlay-header-logo'>Federal Reserve Bank Philadelphia</a> }
                    <button className='overlay-close' type='button' aria-label='Close' onClick={ this.closeOverlay }>&times;</button>
                </div>
                <div className='overlay-content'>
                    <SearchBox
                        searchPagePath={ this.props.searchPagePath }
                        shouldFocus={ true }
                        endpoint={ this.props.endpoint }
                    />

                    { !!this.props.suggestedSearch &&
                        <SuggestedSearch data={ this.props.suggestedSearch } />
                    }
                </div>
            </div>,
            this.props.wrapper
        );

        this.props.wrapper.classList.add( 'expanded' );

        return portal;

    };

    render() {

        return this.state.isOpen ? this.renderOverlay() : null;

    }

}