import vps from 'viewportjs';

export default vps( [
    {
        name: 'small',
        query: '( min-width: 0px )'
    },
    {
        name: 'medium',
        query: '( min-width: 768px )'
    },
    {
        name: 'large',
        query: '( min-width: 1236px )'
    }
] );
