// Polyfills
// https://github.com/github/fetch#readme
import 'whatwg-fetch';
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * API for setting up page mediators.
 */
class Mediator {

    /**
     * Global namespace for data provided by CMS.
     */
    ns = 'FRBP';

    /**
     * Setup and configure a mediator.
     *
     * @param {object} mediator - A page mediator.
     */
    async create( mediator ) {


        this.mediator = this.bindMethods( mediator );
        this.mediator.components = [];
        this.componentConfigs = window[ this.ns ].components;

        await this.configureComponents();

        this.mediator.init && this.mediator.init();
        this.mediator.ready && this.ready( this.mediator.ready );

    }

    /**
     * Attach a method to the `DOMContentLoaded` event.
     *
     * @param {Function} method - The method to call when the DOM is ready.
     */
    ready( method ) {

        if ( document.readyState === 'loading' ) {

            document.addEventListener( 'DOMContentLoaded', method );

        }
        else {

            method();

        }

    }

    /**
     * Initialize components that have configurations
     */
    async configureComponents() {

        const components = this.filterConfiguredComponents( this.mediator.componentClasses );

        for ( const [ component, config ] of components ) {

            let Component = component;

            if ( typeof component === 'object' ) {

                ( { default: Component } = await component.module() );

            }

            this.initializeComponent( Component, config );

        }

        console.assert(
            components.length === this.componentConfigs.length,
            'There are component configurations that were not initiated. Please make sure the page mediatior\'s `componentClasses` array has all possible components and the components classes `name` property matches its respective configuration `name`.'
        );

    }

    filterConfiguredComponents( components ) {

        return this.componentConfigs.reduce( ( accum, config ) => {

            const component = components.find( ( { name } ) => {

                return config.name === name;

            } );

            if ( component ) accum.push( [ component, config ] );

            return accum;

        }, [] );

    }

    initializeComponent( Component, config ) {

        config.instance = new Component( config );

        this.ready( config.instance.ready.bind( config.instance ) );

        this.mediator.components.push( config );

    }

    bindMethods( mediator ) {

        const members = Object.getOwnPropertyNames( mediator );

        members.forEach( ( member ) => {

            const descriptor = Object.getOwnPropertyDescriptor( mediator, member );

            if ( typeof descriptor.value !== 'function' ) return;

            Object.defineProperty( mediator, member, {
                writable: true,
                configurable: true,
                value: descriptor.value.bind( mediator )
            } );

        } );

        return mediator;

    }

}

export default new Mediator;


