import './css/index.css';
import React from 'react';
import { render } from 'react-dom';
import globalVPS from 'js/global-vps';
import focusTrap from 'js/focus-trap';
import checkKeys from 'js/check-keys';
import Overlay from './overlay/index';

export default class {

    static name = 'global-nav';

    constructor( config ) {

        Object.assign( this, config );

        this.state = {
            smallNavOpen: false,
            smallNavActive: false
        };

    }

    ready() {

        this.menu = document.querySelector( '.nav-menu button' );
        this.navLinks = document.querySelector( '#nav-links' );
        this.navTopic = document.querySelector( '.nav-topic' );
        this.navTopicItems = document.querySelectorAll( '.nav-topic > li' );
        this.navInstitution = document.querySelector( '.nav-institution' );
        this.searchBox = document.querySelector( '.search-results .search-box' );
        this.focusTrap = focusTrap( this.element );

        this.setupOverlayWrapper();
        this.bindNavSearchElements();
        this.setNavSearchElement();
        this.setupVPSubscription();

    }

    /**
     * Update this component's options object
     *
     * @param {Object} options - The options to add/override.
     */
    setOptions = ( options ) => {

        Object.assign( this.options, options );

    };

    hasDisplay = ( element ) => {

        const style = document.defaultView.getComputedStyle( element );
        const value = style.getPropertyValue( 'display' );

        return !!value && value !== 'none';

    };

    setupVPSubscription = () => {

        globalVPS( ( state ) => {

            this.setNavSearchElement();
            this.moveInstitutionLinks( globalVPS.current( 'large' ) );

            if ( !globalVPS.current( 'large' ) ) {

                this.setupNavigation();

            }
            else if ( this.state.smallNavActive ) {

                this.moveInstitutionLinks( 'afterbegin' );
                this.cleanupNavigation();

            }

        } );

    };

    moveInstitutionLinks = () => {

        if ( globalVPS.current( 'large' ) ) {

            this.navLinks.insertBefore( this.navInstitution, this.navTopic );

        }

        else {

            this.navLinks.appendChild( this.navInstitution );

        }

    };

    setupOverlayWrapper = () => {

        this.overlayWrapper = document.createElement( 'div' );
        this.overlayWrapper.className = 'overlay-wrapper';
        document.body.insertAdjacentElement( 'beforeend', this.overlayWrapper );

    };

    handleSearch = ( e ) => {

        if ( !this.options.showSearchOverlay ) return this.searchBox.focus();

        render(
            <Overlay
                suggestedSearch={ this.attributes.suggestedSearch }
                searchPagePath={ this.options.searchPagePath }
                endpoint={ this.options.endpoint }
                isOpen={ true }
                refocusElement={ this.navSearch }
                wrapper={ this.overlayWrapper }
            />,
            this.overlayWrapper
        );

    };

    bindNavSearchElements = () => {

        const elements = document.querySelectorAll( '.nav-search button' );

        [ ...elements ].forEach( ( el ) => el.addEventListener( 'click', this.handleSearch ) );

    };

    setNavSearchElement = () => {

        const elements = document.querySelectorAll( '.nav-search button' );
        const button = [ ...elements ].find( ( el ) => this.hasDisplay( el.parentNode ) );

        this.navSearch = button;

    };

    setupNavigation = () => {

        if ( this.state.smallNavActive ) return;

        this.menu.addEventListener( 'click', this.handleMenuExpanding );
        this.navTopic.addEventListener( 'click', this.handleSubTopicsExpanding );

        this.state.smallNavActive = true;

    };

    cleanupNavigation = () => {

        if ( !this.state.smallNavActive ) return;

        this.focusTrap.off();
        this.menu.removeEventListener( 'click', this.handleMenuExpanding );
        this.navTopic.removeEventListener( 'click', this.handleSubTopicsExpanding );
        this.element.classList.remove( 'expanded' );
        document.body.setAttribute( 'aria-hidden', false );
        document.removeEventListener( 'keydown', this.closeNavigationOnEscape );

        this.state.smallNavActive = false;
        this.state.smallNavOpen = false;

    };

    closeNavigationOnEscape = ( e ) => {

        checkKeys( e, 'escape', ( e ) => this.handleMenuExpanding( e ) );

    };

    handleMenuExpanding = ( e ) => {

        const isCloseAction = checkKeys( e, 'escape' ) && this.state.smallNavOpen;
        const isToggleAction = checkKeys( e, [ 'click', 'enter', 'space' ] );

        if ( !isToggleAction && !isCloseAction ) return;

        const didExpand = this.element.classList.toggle( 'expanded' );

        if ( didExpand ) {

            this.state.smallNavOpen = true;
            this.focusTrap.on();
            document.body.setAttribute( 'aria-hidden', true );
            document.addEventListener( 'keydown', this.closeNavigationOnEscape );

        }
        else {

            this.state.smallNavOpen = false;
            this.closeAllSubTopics();
            this.focusTrap.off();
            this.menu.focus();
            document.body.setAttribute( 'aria-hidden', false );
            document.removeEventListener( 'keydown', this.closeNavigationOnEscape );

        }

    };

    handleSubTopicsExpanding = ( e ) => {

        const el = e.target;
        const parent = el.parentNode;
        const isButton = el.nodeName.toLowerCase() === 'button';

        if ( !isButton ) return;

        e.preventDefault();

        const didExpand = parent.classList.toggle( 'expanded' );

        if ( didExpand ) el.setAttribute( 'aria-expanded', true );
        else el.setAttribute( 'aria-expanded', false );

    };

    closeAllSubTopics = () => {

        [ ...this.navTopicItems ].forEach( ( item ) => {

            if ( !item.classList.contains( 'expanded' ) ) return;

            item.classList.remove( 'expanded' );

            item.querySelector( 'button' ).setAttribute( 'aria-expanded', false );

        } );

    };

}