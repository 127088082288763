/**
 * Check for common keyboard actions.
 *
 * @example
 *      checkInputType( e, 'shift click', fn );
 *      checkInputType( e, [ 'escape', 'enter' ], fn );
 *      checkInputType( e, [ 'tab', 'shift tab' ], fn );
 *
 * @param {Event} e - The event object.
 * @param {String|Array} types - The name(s) of the inputs to check.
 * @param {Function} [fn] - A function to call if 1 of the `types` match.
 *
 * @returns {boolean}
 */
export default ( e, types, fn ) => {

    types = Array.isArray( types ) ? types : [ types ];

    const input = {

        // Event Types
        click: e.type === 'click',
        keydown: e.type === 'keydown',

        // Modifier Keys
        alt: e.altKey,
        command: e.metaKey,
        control: e.ctrlKey,
        shift: e.shiftKey,

        // Non-Modifier Keys
        arrowleft: e.keyCode === 37,
        arrowright: e.keyCode === 39,
        arrowup: e.keyCode === 38,
        arrowdown: e.keyCode === 40,
        enter: e.keyCode === 13,
        escape: e.keyCode === 27,
        space: e.keyCode === 32,
        tab: e.keyCode === 9
    };

    const check = ( inputs ) => inputs.split( ' ' ).every( ( action ) => input[ action ] );

    const result = types.some( ( type ) => check( type ) );

    if ( result && fn ) fn( e, result );

    return result;

};