/*
 *  Trap focus within a container
 */

/**
 * Focus the provided element.
 *
 * @param {Event} e - The event object.
 * @param {HTMLElemnt} element - A focusable element.
 */
const moveFocus = ( e, element ) => {

    e.preventDefault();
    element.focus();

};

/**
 * Handle the `keydown` event to manage focus.
 *
 * @param {Event} e - The event object.
 */
const handler = ( e ) => {

    const isTab = e.key === 'Tab' || e.keyCode === 9;
    const isShiftTab = isTab && e.shiftKey;

    if ( !isTab ) return;

    const selector = [
        'button:not([disabled])',
        'select:not([disabled])',
        'a[href]:not([disabled])',
        'textarea:not([disabled])',
        '[tabindex]:not([disabled])',
        'input[type="text"]:not([disabled])',
        'input[type="radio"]:not([disabled])',
        'input[type="checkbox"]:not([disabled])'
    ].join( ',' );

    const focusableEls = e.currentTarget.querySelectorAll( selector );
    const focusedEl = document.activeElement;
    const firstEl = focusableEls[ 0 ];
    const lastEl = focusableEls[ focusableEls.length - 1 ];
    const isFirstEl = focusedEl === firstEl;
    const isLastEl = focusedEl === lastEl;

    if ( !isShiftTab && isLastEl ) moveFocus( e, firstEl );
    else if ( isShiftTab && isFirstEl ) moveFocus( e, lastEl );

};

/**
 * Setup a focus trap.
 *
 * @param {HTMLElemnt} wrapper - A container that contains focusable elements.
 *
 * @returns {Object} The `on` and `off` methods.
 */
export default ( wrapper ) => {

    return {
        on: () => wrapper.addEventListener( 'keydown', handler ),
        off: () => wrapper.removeEventListener( 'keydown', handler )
    };

};