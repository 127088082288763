import './css/index.css';
import React, { Component, createRef } from 'react';
import { func, string, bool } from 'prop-types';
import queryString from 'query-string';
import Autosuggest from 'react-autosuggest';
import throttle from 'lodash.throttle';

export default class extends Component {

    static propTypes = {
        term: string,
        requestResults: func,
        searchPagePath: string,
        shouldFocus: bool,
        endpoint: string,
        searchType: string
    };

    constructor( props ) {

        super( props );

        const termValue = props.term || '';

        this.state = {
            value: termValue,
            suggestions: [],
            disableSubmit: termValue === ''
        };

        this.searchInput = createRef();

    }

    /**
     * On change method for the input, also requred for autosuggest
     *
     * @param {object} event - The event object.
     * @param {string} [newValue] - the new value of the input as it was changed
     */
    onChange = ( event, { newValue } ) => {

        this.setState( ( prevState, props ) => {

            const isTrimmedValueEmpty = newValue.trim() === '';
            const isValueDifferent = prevState.value !== newValue;
            const isDisabledDifferent = prevState.disableSubmit !== isTrimmedValueEmpty;

            if ( isDisabledDifferent && isValueDifferent ) {

                return {
                    value: newValue,
                    disableSubmit: isTrimmedValueEmpty
                };

            }
            else if ( isDisabledDifferent ) return { disableSubmit: isTrimmedValueEmpty };
            else if ( isValueDifferent ) return { value: newValue };

        } );

    };

    handleSearch = ( event ) => {

        let action;
        const params = {
            pageStart: 1,
            pageSize: 12,
            searchQuery: this.state.value,
            searchType: this.props.searchType
        };

        event.preventDefault();

        if ( !this.props.searchPagePath ) {

            action = () => this.props.requestResults( params );

        }

        else {

            const submitEvent = document.createEvent( 'Event' );

            submitEvent.initEvent( 'submit', true, true );


            action = () => {

                // For firefox, we need to dispatch the actual submit event as well as `submit()` beacause it does not actually send off the submit event: https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit
                document.searchBoxForm.submit();
                document.searchBoxForm.dispatchEvent( submitEvent );

            };

        }

        return action();

    };

    /**
     * Autosuggest Required Method to return the actual suggestions(results)
     *
     * @param {string} [value] - query value (value in the input)
     */
    getSuggestions = async ( value ) => {

        const params = queryString.stringify( {
            query: value
        } );

        const results = await fetch( `${ this.props.endpoint }?${ params }` );
        const json = await results.json();

        return json;

    };

    /**
     * Autosuggest Required Method called when we need the value of the suggestion (for display)
     *
     * @param {string} [suggestion] - the suggestion value
     *
     * @returns {string}
     */
    getSuggestionValue = ( suggestion ) => {

        // Returning the actual value of the input so the input value doesn't change as you are keying through the suggestions
        return this.state.value;

    };

    /**
     * Autosuggest optional method that tells it when suggestions should be rendered
     *
     * @param {string} [value] - the value in the input as the user is typing
     *
     * @returns {string}
     */
    shouldRenderSuggestions = ( value ) => {

        // render suggestions when there are more than 3 characters in the input
        return value.trim().length > 3;

    };

    /**
     * Autosuggest Required Method called when suggestions might need to be re-calculated
     *
     * @param {string} [value] - value of the input
     * @param {string} [reason] - reason why new suggestions should be requested
     */
    onSuggestionsFetchRequested = async ( { value, reason } ) => {

        if ( reason === 'input-changed' && this.state.value !== value ) {

            this.setState( {
                suggestions: await this.getSuggestions( value )
            } );

        }

    };

    /**
     * Autosuggest Required (unless `alwaysRenderSuggestions` is set to true) Method called when suggestions might need to be cleared
     */
    onSuggestionsClearRequested = () => {

        this.setState( { suggestions: [] } );

    };

    /**
     * Autosuggest Optional method.
     *
     * @param {object} event - The event object.
     * @param {string} [suggestion] - The selected suggestion.
     */
    onSuggestionSelected = ( event, { suggestion } ) => {

        event.persist();

        this.setState( { value: suggestion }, () => {

            this.handleSearch( event );

        } );

    };

    /**
     * Autosuggest optional method that creates the markup for the searchbox input
     *
     * @param {object} [inputProps] - properties to pass to the input
     *
     * @returns {HTMLElement}
     */
    renderInputComponent = ( inputProps ) => {

        return (
            <label>
                <span className='magnifying-glass' />
                <input { ...inputProps } />
            </label>
        );

    };

    /**
     * Autosuggest Required Method that creates the individual suggestion markup. Note: users are allowed to use special characters but to still show the actual search value in the suggestions we remove the special characters.
     *
     * @param {string} [suggestion] - the suggestion value
     * @param {string} [query] - the value of the query that was sent to get the suggestions
     *
     * @returns {HTMLElement}
     */
    renderSuggestion = ( suggestion, { query, isHighlighted } ) => {

        const string = query.replace( /[^a-zA-Z0-9]/g, '' );
        const pattern = new RegExp( `(${ string })`, 'i' );
        const groups = suggestion.split( pattern );

        return (
            <button type='submit' onSubmit={ this.handleSearch } className={ isHighlighted ? 'highlighted' : '' }>
                { groups.map( ( group, index ) => {

                    if ( pattern.test( group ) ) return <span key={ index } className='key'>{ group }</span>;

                    if ( group !== '' ) return <span key={ index } className='finished-param'>{ group }</span>;

                } ) }
            </button>
        );

    };

    render() {

        const typeAheadTheme = {
            container: 'search-box-container',
            suggestionsContainer: 'type-ahead-suggestions-container'
        };

        const inputProps = {
            type: 'text',
            name: 'searchQuery',
            autoFocus: this.props.shouldFocus,
            className: 'search-box',
            value: this.state.value,
            onChange: this.onChange,
            placeholder: 'What are you looking for?'
        };

        return (
            <form autoComplete='off' className='search-box-form' name='searchBoxForm' action={ this.props.searchPagePath } method='get'>

                <div className='form-elements'>
                    <input type='hidden' name='pageStart' value='1' />

                    <input type='hidden' name='pageSize' value='12' />

                    <Autosuggest
                        theme={ typeAheadTheme }
                        inputProps={ inputProps }
                        focusInputOnSuggestionClick={ false }
                        suggestions={ this.state.suggestions }
                        renderSuggestion={ this.renderSuggestion }
                        getSuggestionValue={ this.getSuggestionValue }
                        renderInputComponent={ this.renderInputComponent }
                        onSuggestionSelected ={ this.onSuggestionSelected }
                        shouldRenderSuggestions={ this.shouldRenderSuggestions }
                        onSuggestionsFetchRequested={ throttle( this.onSuggestionsFetchRequested, 1000 ) }
                        onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
                    />
                </div>

                <button className='button submit-button' type='submit' disabled={ this.state.disableSubmit } onClick={ this.handleSearch }>Search</button>
            </form>
        );

    }

}